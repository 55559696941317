import React from "react";
import classNames from "classnames";
import Form from "@molecules/Form";
import { Container, Text, Icon } from "@atoms";

const FormBlock = ({ heading, formid, color }) => {
  const isDark =
    color === "pink" ||
    color === "purple" ||
    color === "teal" ||
    color === "darkTeal" ||
    color === "darkerTeal";

  return (
    <Container>
      <div
        className={classNames(
          "relative overflow-hidden rounded-lg p-4 shadow-lg sm:p-6 md:p-8 lg:py-12 lg:px-36 xl:py-16 xl:px-48",
          {
            "bg-teal": color === "teal",
            "bg-pink": color === "pink",
            "bg-purple": color === "purple",
          }
        )}
      >
        <div className="relative z-10 flex flex-col items-center gap-5 sm:gap-10">
          <Text
            variant="h2"
            className={classNames("text-center", {
              "text-white": isDark,
            })}
          >
            {heading}
          </Text>
          <Form formId={formid} />
        </div>
        <Icon
          name="logoIcon"
          className="absolute -bottom-5 -right-5 h-auto w-48 -rotate-12 text-white opacity-10 md:w-64"
        />
      </div>
    </Container>
  );
};

FormBlock.defaultProps = {};

export default FormBlock;
